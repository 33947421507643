import { ArrowPathIcon } from "@heroicons/react/20/solid";
import React, { FC, ReactNode } from "react";
import { Tooltip } from "rizzui";
import { HappySvg, SadSvg, UnHappySvg } from "../../../../Images/icons/Icons";

interface MoodOption {
  title: string;
  value: number;
  icon: ReactNode;
}

const moodOptions: MoodOption[] = [
  {
    title: "Happy",
    value: 1,
    icon: <HappySvg width={70} height={70} />,
  },
  {
    title: "Neutral",
    value: 2,
    icon: <UnHappySvg width={70} height={70} />,
  },
  {
    title: "Sad",
    value: 3,
    icon: <SadSvg width={70} height={70} />
  },
];

type SymptomsProp = {
  mood: number | null;
  setMood: React.Dispatch<React.SetStateAction<number>>;
  resetValuesHandler: () => void;
};

const Symptoms: FC<SymptomsProp> = ({ mood, setMood, resetValuesHandler }) => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center flex-col mb-4">
        <div className="relative flex justify-center items-center w-full">
          <h5 className="text-2xl mb-1">Symptoms</h5>
          <div className="absolute right-2 cursor-pointer" onClick={() => resetValuesHandler()}>
            <Tooltip
              content="Reset"
              placement="top"
            >
              <ArrowPathIcon className="w-5 h-5" />
            </Tooltip>
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-sm text-center">
            How are you feeling about your symptoms today?
          </p>
          <p className="text-sm text-center">
            Select the face that
            best represents your current state of mind
          </p>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 px-3">
        {moodOptions.map(({ icon, value, title }, index) => (
          <React.Fragment key={index}>
            <h6 className="flex flex-col justify-center items-center text-2xl">
              {title}
            </h6>

            <div className="flex justify-center items-center">
              <input
                type="radio"
                defaultChecked={mood === value}
                className="hidden"
              />
              <span
                className={`transition-all duration-300 border-2 rounded-full cursor-pointer ${mood === value ? "border-blue-500 " : ""
                  }`}
                onClick={() => setMood(value)}
              >
                {icon}
              </span>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Symptoms;
