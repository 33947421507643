import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import { ComponentProps, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { months } from "../../../../core/constants/date_time";
import { RootState, useAppSelector } from "../../../../redux/store";

import { isSameDate } from "../../../../core/utilities";
import { JournalEntry } from "../../../../types/journal";
import JournalEntries from "../journal/JournalEntries";
import DiaryCharts from "./DiaryCharts";
import StatusTab from "./StatusTab";

interface OverviewProps {
  gotoJournalTab: (editEntry?: JournalEntry) => void;
}
const SCROLL_BOX_MIN_HEIGHT = 20;

const Overview: FC<OverviewProps> = ({ gotoJournalTab }) => {
  const { journalEntries } = useAppSelector(
    (state: RootState) => state.journals
  );
  const [hovering, setHovering] = useState(false);
  const [scrollBoxHeight, setScrollBoxHeight] = useState(SCROLL_BOX_MIN_HEIGHT);
  const [scrollBoxTop, setScrollBoxTop] = useState(0);
  const [shouldShowScroll, setShouldShowScroll] = useState(false); // Control scrollbar visibility
  const scrollHostRef = useRef<HTMLDivElement | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const todayDate = new Date();


  const entriesByDate = useMemo(() => {
    if (selectedDate === null) return journalEntries;
    return journalEntries.filter((entry) =>
      isSameDate(new Date(entry.date), selectedDate as Date)
    );
  }, [journalEntries, selectedDate]);

  const handleDateChange: ComponentProps<typeof Calendar>["onChange"] = (
    date,
    _event
  ) => {
    if (date == null || Array.isArray(date)) return;
    if (
      selectedDate &&
      selectedDate.toDateString() === new Date(date).toDateString()
    ) {
      setSelectedDate(null);
    } else {
      setSelectedDate(new Date(date));
    }
  };

  const editJournalEntry = (journalEntry: JournalEntry) => {
    gotoJournalTab(journalEntry);
  };

  const handleMouseOver = useCallback(() => {
    if (shouldShowScroll) setHovering(true);
  }, [shouldShowScroll]);

  const handleMouseOut = useCallback(() => {
    setHovering(false);
  }, []);

  const handleScroll = useCallback(() => {
    const scrollHostElement = scrollHostRef.current;
    if (!scrollHostElement) return;

    const { scrollTop, scrollHeight, clientHeight } = scrollHostElement;
    const scrollBoxPercentage = clientHeight / scrollHeight;
    const scrollbarHeight = Math.max(scrollBoxPercentage * clientHeight, SCROLL_BOX_MIN_HEIGHT);
    const newTop = (scrollTop / scrollHeight) * clientHeight;

    setScrollBoxHeight(scrollbarHeight);
    setScrollBoxTop(Math.min(newTop, clientHeight - scrollbarHeight));
  }, [SCROLL_BOX_MIN_HEIGHT]);

  useEffect(() => {
    const scrollHostElement = scrollHostRef.current;
    if (!scrollHostElement) return;

    const { clientHeight, scrollHeight } = scrollHostElement;
    const contentOverflow = scrollHeight > clientHeight;

    if (contentOverflow) {
      setShouldShowScroll(true);
      const scrollBoxPercentage = clientHeight / scrollHeight;
      const scrollbarHeight = Math.max(scrollBoxPercentage * clientHeight, SCROLL_BOX_MIN_HEIGHT);
      setScrollBoxHeight(scrollbarHeight);
    } else {
      setShouldShowScroll(false);
    }

    scrollHostElement.addEventListener("scroll", handleScroll);
    return () => {
      scrollHostElement.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, SCROLL_BOX_MIN_HEIGHT, entriesByDate]);

  return (
    <div className="container px-0 overview-tab-container">
      <StatusTab />

      <div className="bg-white rounded-lg overflow-y-auto scrollbar border border-[#C9C8C8] max-h-[calc(72vh-150px)] min-h-[calc(72vh-150px)]">
        <div className="inline-block w-full px-3">
          <DiaryCharts  gotoJournalTab= {gotoJournalTab} className={"pt-3"} />
        </div>
        <div className="grid grid-cols-2 gap-3 my-3 px-3">
          <div className="flex flex-col mt-[25px]">
            <Calendar
              className="!w-full overview-calendar overflow-hidden mb-2"
              onChange={handleDateChange}
              value={selectedDate}
            />
            <p className="text-lg text-center">
              {
                months[
                selectedDate ? selectedDate.getMonth() : todayDate.getMonth()
                ]
              }{" "}
              {selectedDate ? selectedDate.getDate() : todayDate.getDate()},{" "}
              {selectedDate
                ? selectedDate.getFullYear()
                : todayDate.getFullYear()}
            </p>
          </div>

          <div className="flex flex-col">
            <div className="flex items-center justify-center mb-1">
              <p className="text-sm text-center">Journal - All Entries</p>
            </div>
            {entriesByDate.length > 0 ? (
              <div
                className="scrollhost-container relative"
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              >
                <div
                  ref={scrollHostRef}
                  className="scrollhost overflow-y-auto max-h-[calc(100vh-694px)] min-h-[252px]"
                  style={{ maxHeight: "calc(100vh-694px) min-h-[252px]" }}
                >
                  <JournalEntries
                    tabDefaultColor="#000000"
                    entries={entriesByDate}
                    editJournalEntry={editJournalEntry}
                  />
                </div>

                {shouldShowScroll && (
                  <div
                    className="scroll-bar absolute right-1 top-0 rounded"
                    style={{ opacity: hovering ? 1 : 0, width: "8px", height: "100%" }}
                  >
                    <div
                      className="scroll-thumb bg-gray-500 opacity-40 rounded"
                      style={{
                        height: `${scrollBoxHeight}px`,
                        top: `${scrollBoxTop}px`,
                        width: "100%",
                        position: "absolute",
                        left: "0px"
                      }}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div
                className="h-24 border-2 cursor-pointer border-black rounded-xl flex items-center justify-center p-3 bg-[#D9D9D9]"
                onClick={() => gotoJournalTab()}
              >
                Click Here to Journal
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
