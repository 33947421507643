import { useAuth0 } from "@auth0/auth0-react";
import { FC, useEffect, useRef } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoadingContainer from "./components/loader/LoadingContainer";
import Unsubscribe from "./components/unsubscribe/Unsubscribe";
import InactivityHandler from "./core/InactivityHandler";
import PrivateRoutes from "./core/Routes/PrivateRoutes";
import PublicRoutes from "./core/Routes/PublicRoutes";
import PlayVideo from "./core/playVideo";
import LayoutProvider from "./providers/LayoutProvider";
import {
  setBackGroundLoading,
  setShowChatBot,
} from "./redux/layout/LayoutSlice";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
  useAppSelector,
} from "./redux/store";
import { getSystemConfigurations } from "./redux/system-configurations/SystemConfigurationsSlice";
import { setAuthenticatedPatientDetails } from "./redux/user/UserSlice";

const App: FC = () => {
  const { isLoading, isAuthenticated, user, getAccessTokenSilently } =
    useAuth0();
  const dispatch: AppDispatch = useAppDispatch();
  const tokenRefreshIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const auth0Audience: string = process.env.REACT_APP_AUTH0_AUDIENCE || "";
  const isPageLoading = useAppSelector(
    (state: RootState) => state.layout.backGroundLoading
  );
  const refreshTime = process.env.GET_REFRESH_TIME
    ? parseInt(process.env.GET_REFRESH_TIME)
    : 4;
  const timeoutDurationRefreshToken = refreshTime * 60 * 1000; // 4 minutes
  const setAuthUserData = async () => {
    const callbackJourney = localStorage
      .getItem("auth0_callback")
      ?.match(/^https?:\/\/[^/]+\/([^/]+)(\/|$)/)?.[1];
    const currentJourney = localStorage.getItem("current-journey");

    const currentJourneySlug = callbackJourney && !callbackJourney.includes('?') ? callbackJourney : currentJourney;

    if (currentJourneySlug == null) {
      console.error("No journey slug found");
      return;
    }

    await dispatch(
      setAuthenticatedPatientDetails({
        currentJourneySlug,
        email: user?.email,
      })
    );
    dispatch(setShowChatBot(false));
  };

  const setSystemConfigurationsData = async (): Promise<void> => {
    await dispatch(getSystemConfigurations());
  };

  const fetchTokenAndSetAxiosHeader = async () => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: auth0Audience,
          scope: "offline_access",
        },
      });
      localStorage.setItem("access_token", token);
    } catch (error) {
      console.error("Error fetching and setting token:", error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      dispatch(
        setBackGroundLoading({
          status: true,
          loadingMessage: "Fetching User Details",
        })
      );
      try {
        await fetchTokenAndSetAxiosHeader().then(async () => {
          await setAuthUserData();
          await setSystemConfigurationsData();
        });
      } catch (error) {
        console.error("Error in useEffect:", error);
      }
      dispatch(setBackGroundLoading({ status: false }));
    };

    if (isAuthenticated && !isLoading) {
      fetchData();
      tokenRefreshIntervalRef.current = setInterval(() => {
        fetchTokenAndSetAxiosHeader();
      }, timeoutDurationRefreshToken);
    }

    if (!isAuthenticated && tokenRefreshIntervalRef.current) {
      clearInterval(tokenRefreshIntervalRef.current);
    }
  }, [isAuthenticated, isLoading, timeoutDurationRefreshToken]);

  return (
    <>
      {isLoading && (
        <LoadingContainer customLoadingMessage="Verifying User Authentication" />
      )}

      <BrowserRouter>
        <Routes>
          <Route path="unsubscribe" element={<Unsubscribe />} />
          <Route path="playVideo" element={<PlayVideo />}></Route>

          <Route
            path="*"
            element={
              <>
                {!isAuthenticated && !isLoading && <PublicRoutes />}
                <LayoutProvider>
                  {isAuthenticated && (
                    <>
                      <PrivateRoutes />
                    </>
                  )}
                  {isPageLoading && <LoadingContainer />}
                </LayoutProvider>
                {isAuthenticated && <InactivityHandler />}
              </>
            }
          />
        </Routes>
      </BrowserRouter>

      <ToastContainer />
    </>
  );
};

export default App;
