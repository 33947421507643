import { ComponentProps, FC, ReactNode } from "react";
import ConditionallyRender from "react-conditionally-render";
import { alertNotification } from "../../../../core/alert/ToastAlert";
import {
  AppointmentActivity,
  AppointmentActivityTypes,
  PhaseActivityStatus,
} from "../../../../core/phase-activity/AppointmentActivityConfig";
import {
  AppointmentProgress,
  AppointmentStatus,
  markActivityAsCompleted,
} from "../../../../redux/appointment/AppointmentSlice";
import { setBackGroundLoading } from "../../../../redux/layout/LayoutSlice";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import GenerateSurveyModal from "../../../survey/GenerateSurveyModal";
import Tabs from "../../../tabs/Tabs";
import { ISummaryTab, TabTypes } from "../Dashboard";
type SummaryProps = {
  activePage?: ISummaryTab;
  activeTab?: string;
  onChangeTab?: (tab: string) => void;
  onComplete?: (tab: string) => void;
};

type SurveyHistoryProps = SummaryProps & {
  activity: AppointmentActivity;
  key: number;
};
export const SurveyHistoryManager: FC<SurveyHistoryProps> = ({
  activity,
  activePage,
  onComplete,
  activeTab,
}) => {
  const dispatch = useAppDispatch();
  const handleHomeworkSubmit: ComponentProps<
    typeof GenerateSurveyModal
  >["onComplete"] = async (data) => {
    //$ dispatch(setActivitySubmitLoader(true));
    const shareFlag = Array.isArray(data) ? data.find((item: { name: string; }) => item.name === "SHARE_FLAG") : null;
    try {
      dispatch(
        setBackGroundLoading({
          status: true,
          loadingMessage: "Submitting details",
        })
      );
      await dispatch(
        markActivityAsCompleted({
          activityId: activity.id,
          phaseSlug: activity?.phase_activity?.journey_phase?.slug,
          data,
        })
      );
      //$ dispatch(setActivitySubmitLoader(false));
      dispatch(
        setBackGroundLoading({
          status: false,
        })
      );
      if (shareFlag?.answer) {
        alertNotification("success", "Email sent successfully", "top-right");
      }
      else {
        alertNotification("success", "Your activity has been updated!", "top-right");
      }
      onComplete?.(`${activeTab}`);
    } catch (error) {
      if (shareFlag?.answer) {
        alertNotification("warning", "Unable to send the email", "top-right");
      }
    }
  };

  const surveyData = modifySurveyData(activity?.phase_activity?.frontier_data);

  return (
    <GenerateSurveyModal
      surveyData={surveyData}
      // surveyData={activity?.phase_activity?.frontier_data} //todo: restore when goals are updated
      external_data={activity?.external_data.filter(item => item.name !== "SHARE_EMAIL")
        .map(item =>
          item.name === "SHARE_FLAG"
            ? { ...item, answer: false }
            : item
        )}
      onComplete={handleHomeworkSubmit}
      showSurveyModal={false}
      showContentInModal={false}
      onClose={() => { }}
      displayWrapper={false}
      currentActivePage={activePage?.page}
    />
  );
};

const Summaries: FC<SummaryProps> = ({
  activePage,
  onChangeTab,
  onComplete,
}) => {
  const appointmentProgresses: AppointmentProgress[] = useAppSelector(
    (state: RootState) => state.appointment.data.appontments
  );

  //$ const ActivitySubmitLoader = useAppSelector(
  //$   (state: RootState) => state.dashboard.activitySubmitLoader
  //$ );

  const completedAppointments: AppointmentProgress[] =
    appointmentProgresses?.filter(
      (item: AppointmentProgress) =>
        item?.status === AppointmentStatus.COMPLETED ||
        item?.status === AppointmentStatus.CURRENT
    );

  const hasSummaries = completedAppointments?.some(
    (appointment: AppointmentProgress) => {
      return appointment?.journey_phase.patient_phase_activity?.some(
        (activity: AppointmentActivity) => {
          return (
            activity?.phase_activity?.type ===
            AppointmentActivityTypes.SUMMARY &&
            activity?.status === PhaseActivityStatus?.COMPLETED
          );
        }
      );
    }
  );

  const allAppointments = completedAppointments?.filter(
    (appointment: AppointmentProgress) => {
      return appointment?.journey_phase.patient_phase_activity?.some(
        (activity: AppointmentActivity) => {
          return (
            activity?.phase_activity?.type ===
            AppointmentActivityTypes.SUMMARY &&
            activity?.status === PhaseActivityStatus?.COMPLETED
          );
        }
      );
    }
  );

  const summaryData = allAppointments
    ?.map((appointment) => {
      return appointment?.journey_phase?.patient_phase_activity?.find(
        (activity) => activity?.phase_activity?.frontier_data
      );
    })
    .filter((appointment) => appointment);

  const tabsData = summaryData?.map<TabTypes>((activity) => {
    return {
      key: activity?.phase_activity?.journey_phase?.slug || "",
      value: activity?.phase_activity?.journey_phase?.title || "",
    };
  });
  //$  const getActivityLoader=()=>{
  //$   if (ActivitySubmitLoader) {
  //$     return <ActivitySkeleton />;
  //$  }
  //$ }

  const renderTabData = (tabKey: string): ReactNode => {
    const currentSummary = summaryData.find(
      (activity) => activity?.phase_activity?.journey_phase?.slug === tabKey
    );

    return (
      <>
        {currentSummary ? (
          <div
            className="inline-block w-full [&>div]:rounded-[0.5rem]"
            key={currentSummary?.id}
          >
            <SurveyHistoryManager
              activity={currentSummary}
              activePage={activePage}
              activeTab={tabKey}
              key={+currentSummary.id}
              onComplete={onComplete}
            />
          </div>
        ) : (
          <>No Tab Data Found !</>
        )}
      </>
    );
  };

  return (
    <ConditionallyRender
      condition={hasSummaries}
      show={
        <div className="grid grid-cols-1 gap-6 px-0">
          {tabsData?.length > 0 ? (
            <Tabs
              currentTab={onChangeTab}
              renderTabData={(data) => renderTabData(data)}
              tabListData={tabsData}
              activeTab={tabsData[0].key}
            />
          ) : null}
        </div>
      }
      elseShow={
        <div className="bg-white p-8 rounded-xl min-h-[calc(72vh-165px)]">
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <svg
              className="h-6 w-6 text-green-600"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
              ></path>
            </svg>
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3
              className="text-base font-semibold leading-6 text-gray-900"
              id="modal-title"
            >
              Keep up with Summaries!
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Summary information are great ways to recall all the medical
                guidance provided by your doctor.
              </p>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default Summaries;

//! TEMPORARY SOLUTION FOR `achieve_date` minimum in journey goal creation
const modifySurveyData = (surveyData: unknown) => {
  if (typeof surveyData !== "object" || surveyData == null) return surveyData;
  if (!("pages" in surveyData) || !Array.isArray(surveyData.pages))
    return surveyData;

  const pages = surveyData.pages;

  const goalPageIdx = pages.findIndex(
    (page: unknown) =>
      typeof page === "object" &&
      page != null &&
      "description" in page &&
      typeof page.description === "string" &&
      page.description.match(/goal/i) != null
  );
  if (
    goalPageIdx < 0 ||
    typeof pages[goalPageIdx] !== "object" ||
    pages[goalPageIdx] == null ||
    !("elements" in pages[goalPageIdx]) ||
    !Array.isArray(pages[goalPageIdx].elements)
  )
    return surveyData;

  const elements = pages[goalPageIdx].elements;

  const achieveDateIdx = elements.findIndex(
    (element: unknown) =>
      typeof element === "object" &&
      element != null &&
      "name" in element &&
      element.name === "achieve_date"
  );

  if (achieveDateIdx < 0) return surveyData;

  let newSurveyData = JSON.parse(JSON.stringify(surveyData));

  const today = new Date();
  const minDate = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    today.getDate()
  );

  newSurveyData.pages[goalPageIdx].elements[achieveDateIdx].min = minDate
    .toISOString()
    .substring(0, 10);
  return newSurveyData;
};
