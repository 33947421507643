import { useEffect, useState } from "react";
import { Tooltip } from "rizzui";
import { CoffeeSvg, DrinkSvg, FilterIcon, WaterSvg } from "../../Images/icons/Icons";
import { moodOptions } from "../patient/dashboard/journal/CreateEntryForm";

interface FilterProps {
  handleCallback: (value: { type: string, value: string }) => void;
  filterClick?: String;
}

const Filter: React.FC<FilterProps> = ({ handleCallback, filterClick }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const filterJSON = [
    {
      id: 'mood',
      title: 'Mood',
      options: moodOptions
    },
    {
      id: 'liquid',
      title: 'Liquid Type',
      options: [
        { value: 'water', title: "Water", icon: <WaterSvg /> },
        { value: 'soda', title: "Soda", icon: <DrinkSvg height={"30px"} width={"20px"} /> },
        { value: 'coffee', title: "Coffee", icon: <CoffeeSvg height={"30px"} width={"22px"} /> },
      ]
    }
  ];
  useEffect(() => {
    if (filterClick !== 'filter') {
      setIsPopupVisible(false)
      setSelectedFilter(null);
    }
  }, [filterClick])

  const handleFilterClick = (filter: string) => {
    setSelectedFilter(filter);
  };

  const handleOpenPopup = () => {
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    setSelectedFilter(null);
  };

  return (
    <div className="relative inline-block">
      {/* Icon */}
      <Tooltip content={"Filter"} placement="top">
        <div className="px-[0.375rem] py-[0.375rem]" onClick={handleOpenPopup}>
          <FilterIcon />
        </div>
      </Tooltip>

      {/* Popup */}
      {isPopupVisible && (
        <div className="absolute right-[1rem] top-[18px] mt-2 bg-slate-50 border border-gray-300 rounded-lg shadow-lg w-48 z-50">
          <div className="p-2">
            {/* Close Button */}
            <div className="flex justify-between items-center border-b-2 !border-black pb-3">
              <span className="flex-grow text-center pt-1 font-bold">Filter</span>
              <div className="z-10">
                <span
                  className="modal-cross-icon cursor-pointer text-sm"
                  onClick={handleClosePopup}
                />
              </div>
            </div>
            <div className="flex flex-col">
              {filterJSON.map((category, index) => (
                <div
                  key={category.id}
                  className={`flex items-center py-3 pl-8 pr-2 cursor-pointer ${index !== filterJSON.length - 1 ? 'border-b !border-black' : ''
                    }`}
                  onClick={() => handleFilterClick(category.id)}
                >
                  {category.options[2].icon}<span className="ml-3 font-medium">{category.title}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {selectedFilter && (
        <div className="absolute right-52 top-[18px] mt-2 bg-slate-50 border border-gray-300 rounded-lg shadow-lg w-48 z-50">
          <div className="p-2">
            <div className="flex justify-between items-center border-b-2 !border-black pb-3">
              <span className="flex-grow text-center pt-1 font-bold">
                Filter <span className="capitalize">{selectedFilter}</span>
              </span>
              <div className="z-10">
                <span
                  className="modal-cross-icon cursor-pointer text-sm"
                  onClick={() => setSelectedFilter(null)}
                />
              </div>
            </div>
            <div className="flex flex-col">
              {filterJSON.find(category => category.id === selectedFilter)?.options.map((item, index, array) => (
                <div
                  key={item.value}
                  className={`flex items-center py-3 pl-9 pr-2 cursor-pointer ${index !== array.length - 1 ? 'border-b !border-black' : ''
                    }`}
                  onClick={() => {
                    handleCallback({ type: selectedFilter, value: item.value.toString() });
                    handleClosePopup();
                  }}
                >
                  {item.icon}<span className="ml-3 font-medium">{item.title}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filter;
