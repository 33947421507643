import { ArrowPathIcon } from "@heroicons/react/20/solid";
import React, { FC } from "react";
import { Tooltip } from "rizzui";
import {
  IntakeCoffeeSvgNew,
  IntakeSodaSvgNew,
  IntakeWaterSvgNew,
  IntakeWineSvg,
} from "../../../../Images/icons/Icons";

export interface LiquidOption {
  name: "soda" | "coffee" | "wine" | "water";
  title: string;
  svg: JSX.Element;
}

const liquidOptions: LiquidOption[] = [
  {
    name: "soda",
    title: "Soda or Energy Drinks",
    svg: <IntakeSodaSvgNew width={40} height={40} />,
  },
  {
    name: "coffee",
    title: "Coffee or Tea",
    svg: <IntakeCoffeeSvgNew width={40} height={40} />,
  },
  {
    name: "wine",
    title: "Wine, Beer or Alcohol",
    svg: <IntakeWineSvg width={40} height={40} />,
  },
  {
    name: "water",
    title: "Water",
    svg: <IntakeWaterSvgNew width={40} height={40} />,
  },
];

type LiquidsProp = {
  liquidCounts: Record<LiquidOption["name"], number>;
  setLiquidCounts: React.Dispatch<
    React.SetStateAction<Record<LiquidOption["name"], number>>
  >;
  resetValuesHandler: () => void;
};

const Liquids: FC<LiquidsProp> = ({ liquidCounts, setLiquidCounts, resetValuesHandler }) => {
  const handleInputChange = (name: LiquidOption["name"], value: string) => {
    let numericValue = parseInt(value, 10);

    if (isNaN(numericValue)) numericValue = 0;
    else if (numericValue < 0) numericValue = 0;
    else if (numericValue > 10) numericValue = 10;

    setLiquidCounts((prevCounts) => ({
      ...prevCounts,
      [name]: numericValue,
    }));
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center flex-col mb-4">
        <div className="relative flex justify-center items-center w-full">
          <h5 className="text-2xl mb-1">Liquids</h5>
          <div className="absolute right-2 cursor-pointer" onClick={() => resetValuesHandler()}>
            <Tooltip
              content="Reset"
              placement="top"
            >
              <ArrowPathIcon className="w-5 h-5" />
            </Tooltip>
          </div>
        </div>
        <p className="text-sm text-center px-3">
          Enter the number of drinks next to each drink type that applies
        </p>
      </div>

      <div className="grid grid-cols-2 gap-2 px-3 mb-3">
        <div className="flex items-center justify-center">
          <p className="text-lg">Drink Type</p>
        </div>

        <div className="flex items-center justify-center">
          <p className="text-lg">Number of Drinks</p>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 px-1">
        {liquidOptions.map((liquid, index) => (
          <React.Fragment key={index}>
            <div className="flex flex-col justify-center items-center">
              {liquid.svg}
              <span className="text-[10px] mt-1">{liquid.title}</span>
            </div>

            <div className="flex justify-center items-center">
              <input
                type="number"
                className="w-9 h-9 p-1 text-center rounded-md text-sm"
                max={10}
                min={0}
                value={liquidCounts[liquid.name]}
                onChange={(e) => handleInputChange(liquid.name, e.target.value)}
                onKeyDown={(e) => {
                  if (['-', '+', '.', 'e'].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
export default Liquids;
