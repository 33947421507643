import ConditionallyRender from "react-conditionally-render";
import { useNavigate } from "react-router";
import { alertNotification } from "../../../../core/alert/ToastAlert";
import { submitPausedJourneyAction } from "../../../../core/requests/_requests";
import {
  AppointmentProgress,
  AppointmentStatus,
  fetchPatientJourneyProgresses,
  JourneyStatus as PatientJourneyStatus,
} from "../../../../redux/appointment/AppointmentSlice";
import { setBackGroundLoading } from "../../../../redux/layout/LayoutSlice";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";

// todo: update to use only 1 source of journey status
const StatusTab = () => {
  // Declaring a variable `statusVal` of type `AppointmentProgress | undefined` and initialize it to `undefined`.
  const navigate = useNavigate();

  const patientJourneyStatus = useAppSelector(
    (state: RootState) => state.appointment.data.journey_status
  );
  const patient = useAppSelector((state: RootState) => state.user.authUser);
  const currentJourneySlug: string = useAppSelector(
    (state: RootState) => state.journey.currentJourneySlug
  );

  const appointments: AppointmentProgress[] = useAppSelector(
    (state: RootState) => state.appointment.data.appontments
  );

  const currentAppointment = appointments?.find(
    (progress) => progress.status === AppointmentStatus.CURRENT
  );
  const firstInProgressActivity =
    currentAppointment?.journey_phase?.patient_phase_activity?.find(
      (activity) => (activity.status as number) === AppointmentStatus.LOCKED //todo: fix the enum mismatch and remove `as`
    );
  const firstCrntInProgressActivity =
    currentAppointment?.journey_phase?.patient_phase_activity?.find(
      (activity) => (activity.status as number) === AppointmentStatus.CURRENT
    );

  const dispatch = useAppDispatch();

  const clickButtonAction = async () => {
    switch (patientJourneyStatus?.status) {
      case PAUSED:
        dispatch(
          setBackGroundLoading({
            status: true,
            loadingMessage: "Activating your journey",
          })
        );
        const data = {
          surveyAnswers: [{ answer: true, name: "resume_journey", dbId: 123 }],
          journey_slug: currentJourneySlug,
        };
        await submitPausedJourneyAction(data).then(async (response) => {
          if (response.continueJourney) {
            await dispatch(
              fetchPatientJourneyProgresses({
                 journeyId: patient.current_journey_id,
              })
            );
            let navigationUrl = `/${currentJourneySlug}/journey-phase/${patientJourneyStatus.pausedJourneyDTO?.slug}`;
            // if (patientJourneyStatus.pausedJourneyPhase?.activityId) {
            //     navigationUrl = navigationUrl + `?activityId=${patientJourneyStatus.pausedJourneyPhase?.activityId}`
            // }
            dispatch(setBackGroundLoading({ status: false }));
            navigate(navigationUrl);
          }
        });
        break;

      case ACTIVE:
        // if (firstInProgressActivity) {
        //   let redirectRoute = `/${currentJourneySlug}/journey-phase/${firstInProgressActivity?.phase_activity?.journey_phase.slug}`;
        //   if (firstInProgressActivity?.id) {
        //     redirectRoute =
        //       redirectRoute + `?activityId=${firstInProgressActivity?.id}`;
        //   }
        //   navigate(redirectRoute);
        // }
        if (firstInProgressActivity) {
          let redirectRoute = `/${currentJourneySlug}/journey-phase/${firstInProgressActivity?.phase_activity?.journey_phase.slug}`;
          const activityId =
            firstInProgressActivity?.id &&
            (firstCrntInProgressActivity?.status === 1
              ? firstCrntInProgressActivity.id
              : firstInProgressActivity.id);
          if (activityId) {
            redirectRoute += `?activityId=${activityId}`;
          }
          navigate(redirectRoute);
        } else {
          if (currentAppointment) {
            let redirectRoute = `/${currentJourneySlug}/journey-phase/${currentAppointment.journey_phase.slug}`;
            const lastCompletedActivity =
              currentAppointment?.journey_phase?.patient_phase_activity &&
              currentAppointment.journey_phase.patient_phase_activity[
              currentAppointment.journey_phase.patient_phase_activity.length -
              1
              ];
            if (lastCompletedActivity) {
              redirectRoute += `?activityId=${lastCompletedActivity.id}`;
            }
            navigate(redirectRoute);
          } else {
            alertNotification(
              "error",
              "No Pending Activity Found !",
              "top-right"
            );
          }
        }
        break;

      default:
        break;
    }
  };

  return (
    <div className="flex justify-center px-3 py-2 space-y-2 rounded-xl">
      <ConditionallyRender
        condition={patientJourneyStatus?.status === ACTIVE}
        show={
          <div className="flex justify-between items-center gap-20">
            <p className="text-base text-black font-inter">
              Journey Status: Your journey is currently active
            </p>
            <div className="inline-block">
              <button
                onClick={clickButtonAction}
                className="tw-btn tw-btn-tealgreen font-inter block w-full capitalize p-2 font-normal rounded-xl"
              >
                Resume Journey
              </button>
            </div>
          </div>
        }
      />
      <ConditionallyRender
        condition={patientJourneyStatus?.status === COMPLETED}
        show={
          <div className="flex justify-between gap-20">
            <p className="text-base text-black font-inter">
              Journey Status: You have completed your journey.
            </p>
          </div>
        }
      />
      <ConditionallyRender
        condition={patientJourneyStatus?.status === PAUSED}
        show={
          <div className="flex justify-between items-center gap-20">
            <p>
              <span className="text-base text-black font-inter">
                Journey Status: Your journey is currently Paused
              </span>
              <span className="ml-1 font-inter">
                {patientJourneyStatus?.pausedJourneyDTO?.title || ""}
              </span>
            </p>
            <div className="inline-block">
              <button
                onClick={clickButtonAction}
                className="tw-btn tw-btn-tealgreen font-inter block w-full capitalize p-2 font-normal rounded-xl"
              >
                {"Resume Journey"}
              </button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default StatusTab;

const { ACTIVE, COMPLETED, PAUSED } = PatientJourneyStatus;
