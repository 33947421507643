import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
} from "axios";
import { SymptomEntry } from "../../components/patient/dashboard/symptom-tracker/SymptomTracker";
import { Schedule } from "../../components/schedule/Scheduling";
import { JourneyGoal, PatientGoal } from "../../redux/dashboard/DashboardSlice";
import { Doctor } from "../../redux/doctor/DoctorSlice";
import { Journey } from "../../redux/journey/JourneySlice";
import { NotificationType } from "../../redux/notification/NotificationSlice";
import { Symptom, verifyOABSymptoms } from "../../redux/symptom/SymptomSlice";
import { Treatment } from "../../redux/treatments/TreatmentSlice";
import { Patient } from "../../redux/user/UserSlice";
import {
  Category,
  MediaResponse,
  Video,
} from "../../redux/video-gallery/VideoGallerySlice";
import {
  FilteredJournalResponse,
  JournalEntry,
  PatientJournal,
} from "../../types/journal";
import { alertNotification } from "../alert/ToastAlert";
import Api, { V1_API } from "./Api";

type redoxPatientSearchBody = {
  given?: string;
  family?: string;
  birthdate?: string;
  auth0_id?: string;
};

type searchRedoxPatientObject = {
  given?: string;
  family?: string;
  birthdate?: string;
};

export type ShareEmailContentType = {
  name: string;
  journey: string;
  videolink: string;
};

const KommunicateServiceBaseUrl = "https://services.kommunicate.io";

const API_VERSIONS = {
  v0: Api,
  v1: V1_API,
} satisfies Record<`v${number}`, AxiosInstance>;

const makeLegacyApiCall = async (
  method: Method = "GET",
  url: string,
  data?: any,
  headers?: Record<string, string>,
  params?: Record<string, any>
) => await makeApiCall("v0", method, url, data, headers, params);

// todo: replace `any` with `unknown` (currently `any` to avoid extensive type checking)
const makeApiCall = async <T = any>(
  version: keyof typeof API_VERSIONS,
  method: Method,
  url: string,
  data?: any,
  headers?: Record<string, string>,
  params?: Record<string, any>,
  config?: AxiosRequestConfig<any>
): Promise<T> =>
  await API_VERSIONS[version]
    .request({ method, url, data, headers, params, ...config })
    .then((d: AxiosResponse) => d.data)
    .catch(
      (
        error: AxiosError<{
          title: string;
        }>
      ) => {
        const getErrorMessage = (responseData: any): string => {
          if (
            typeof responseData === "string" &&
            responseData.trim().length > 0
          ) {
            return responseData;
          }
          return "Something went wrong";
        };
        if (url !== "/patient/findDoctor") {
          if (error?.response?.data) {
            const errorMessage = getErrorMessage(error.response.data);
            alertNotification("error", errorMessage);
            return errorMessage;
          }

          alertNotification("error", getErrorMessage(error.message));
        }
      }
    );

// Patients requests...

// new migration api for searching the patient in redox
export const searchRedoxPatient = async (
  patientDetails: searchRedoxPatientObject
) =>
  await makeApiCall<Patient>("v1", "POST", "patient/legacy/registerPatient", {
    ...patientDetails,
  });

export const redoxPatientSearch = async (
  patientDetails: redoxPatientSearchBody
) => await makeLegacyApiCall("POST", "/redox-search", { ...patientDetails });

export const patientCreate = async (patientDetails: redoxPatientSearchBody) =>
  await makeLegacyApiCall("POST", "/patients", {
    ...patientDetails,
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

// new migration api to get the authenticated patient
export const getAuthenticatedPatient = async (params: {
  journeySlug?: string;
  email?: string;
  timezone: string;
}) =>
  await makeApiCall<{ patient: Patient; exist: boolean }>(
    "v1",
    "GET",
    "patient/legacy/findByAuth",
    undefined,
    undefined,
    params
  );

export const patientUpdateRequest = async (patientDetails: any) =>
  await makeApiCall("v1", "PUT", "/patient/legacy/updateByAuth", {
    ...patientDetails,
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

export const updatePatientAvatar = async (avatar: any) =>
  await makeLegacyApiCall(
    "POST",
    "patient-avatar",
    { avatar },
    { "Content-Type": "multipart/form-data" }
  );

export const updatePatientAvatarNew = async (avatar: File) =>
  await makeApiCall(
    "v1",
    "PUT",
    "/patient/updateAvatar",
    { file: avatar },
    { "Content-Type": "multipart/form-data" }
  );

// Doctors requests...

export const getAllDoctors = async (journeyId: number) =>
  await makeApiCall<Doctor[]>(
    "v1",
    "GET",
    `/provider/legacy/findByJourney/${journeyId}`
  );

export const getSelectedDoctor = async () =>
  await makeApiCall<Doctor>("v1", "GET", `/patient/findDoctor`);

// Journey requests...

export const getPatientjourneys = async () =>
  await makeLegacyApiCall("GET", "journeys");

// new migration api to get all the journeys
export const getAllPatientjourneys = async () =>
  await makeApiCall<Journey[]>("v1", "GET", `journey/legacy/findAll`);

export const getPatientJourneyProgresses = async (journeySlug: string) =>
  await makeLegacyApiCall("GET", `patient-journey-progresses/${journeySlug}`);

export const markPhaseActivityAsCompleted = async (
  phaseActivityUuid: string = "0",
  body?: { JourneyPhaseSlug?: string; data?: Object },
  isExternalData = true
) => {
  // return await makeLegacyApiCall(
  //   "PUT",
  //   `/patient-phase-activities/${phaseActivityId}`,
  //   { journey_phase_slug: body?.JourneyPhaseSlug, external_data: body?.data }
  // );
  let payloadData = body?.data;
  if (isExternalData) {
    payloadData = { external_data: body?.data };
  }
  return await makeApiCall(
    "v1",
    "PUT",
    `patientActivity/legacy/completeActivity/uuid/${phaseActivityUuid}`,
    { journey_phase_slug: body?.JourneyPhaseSlug, ...payloadData }
  );
};

export const getPatientJourneyPhaseProgressComplete = async (
  journeyProgressUuid: string
) =>
  await makeApiCall(
    "v1",
    "PUT",
    `journeyProgress/legacy/completePhase/uuid/${journeyProgressUuid}`
  );
/** Below is the Legacy API, Just for reference keeping it for now*/
// await makeLegacyApiCall(
//   "PUT",
//   `patient-journey-progresses/${journeyPhaseId}`
// );

// Patient Health Records Api start...

export const getHealthDemographicInformation = async () =>
  await makeLegacyApiCall(
    "GET",
    `patient-personal-health-demographic-information`
  );

export const getPatientAllergies = async () =>
  await makeLegacyApiCall("GET", `patient-personal-health-allergies`);

export const getPatientMedication = async () =>
  await makeLegacyApiCall("GET", `patient-personal-health-medications`);

export const getPatientAppointment = async () =>
  await makeLegacyApiCall("GET", `patient-personal-health-appointment`);

export const getPatientInsurance = async () =>
  await makeLegacyApiCall("GET", `patient-personal-health-insurance`);

// Notification API...

export const getAllNotifications = async (params: {
  showRead: boolean;
}): Promise<NotificationType[]> =>
  await makeApiCall<NotificationType[]>(
    "v1",
    "GET",
    `/notification/findByAuth`,
    undefined,
    undefined,
    params
  );

export const markNotificationAsRead = async (notificationUuid: string) =>
  await makeApiCall<void>(
    "v1",
    "PUT",
    `/notification/read/uuid/${notificationUuid}`
  );

export const markAllNotificationAsRead = async () =>
  await makeApiCall<void>("v1", "PUT", `/notification/readAll`);

// Video Gallery API...
export const getVideosByJourney = async (
  journeyId: number,
  params?: { filterType?: "all" | "favorite" | "history"; categoryId?: number }
) =>
  await makeApiCall<Video[]>(
    "v1",
    "GET",
    `media/legacy/findVideosByJourney/${journeyId}`,
    undefined,
    undefined,
    params
  );

export const getVideoCategoriesByJourney = async (journeyId?: number) =>
  await makeApiCall<Category[]>(
    "v1",
    "GET",
    `media/legacy/findVideoCategoriesByJourney/${journeyId}`
  );

// migration api
export const addVideoToHistory = async (mediaId: number) =>
  await makeApiCall<void>(
    "v1",
    "POST",
    `videoHistory/addHistoryByAuth/${mediaId}`
  );

// migration api
export const removeVideoFromHistory = async (mediaId: number) =>
  await makeApiCall<void>(
    "v1",
    "DELETE",
    `videoHistory/removeHistoryByAuth/${mediaId}`
  );

// migration api
export const addToFavouritesOrRemove = async (mediaId: number) =>
  await makeApiCall<void>(
    "v1",
    "POST",
    `videoFavorite/toggleFavoriteByAuth/${mediaId}`
  );

export const getSearchResults = async (
  searchQuery?: string,
  journey_slug?: string
) =>
  await makeApiCall<Video[]>("v1", "POST", "media/legacy/videoSearch", {
    search_query: searchQuery,
    journey_slug,
  });

export const uploadMedia = async (data?: Object) =>
  // await makeApiCall<any>("v1", "POST", "media/upload", data);
  await V1_API.postForm<MediaResponse>("media/upload", data);
// Symptoms API...

// new migration api to get the list of symptoms for OAB
export const getPatientJourneySymptoms = async (params: {
  journeyId?: number;
}) =>
  await makeApiCall<Symptom[]>(
    "v1",
    "GET",
    `symptom/legacy/getPatientSymptoms?journeyId=${params?.journeyId}`
  );

// export const getJourneySymptoms = async (journey_slug?: string) =>
//   await makeLegacyApiCall("GET", `symptoms?journey_slug=${journey_slug}`);

// new migration api to verify the symptoms for OAB
export const verifyPatientSymtoms = async ({
  journeyId,
  patientActivityUuid,
  symptom_id,
}: {
  journeyId?: number;
  patientActivityUuid: string;
  symptom_id: number[];
}) =>
  await makeApiCall<verifyOABSymptoms>(
    "v1",
    "POST",
    `symptom/legacy/savePatientSymptoms?journeyId=${journeyId}&patientActivityUuid=${patientActivityUuid}`,
    { symptom_id }
  );

export const submitPatientSymtoms = async ({
  journey_slug,
  symptom_id,
}: {
  journey_slug?: string;
  symptom_id: number[];
}) =>
  await makeLegacyApiCall("POST", "patient-symptoms", {
    journey_slug,
    symptom_id,
  });

// Journey Pause requests...

export const patientPausedJourneyDetails = async () =>
  await makeLegacyApiCall("GET", "patient-paused-journey-details");

export const submitPausedJourneyAction = async (data?: any) =>
  await makeApiCall("v1", "POST", "journey/restartJourney", { ...data });

// Kommunicate requests...

export const createKommunicateConversation = async ({
  groupName,
  groupMemberList,
}: {
  groupName: string;
  groupMemberList?: string[];
}) =>
  await axios.post(
    `${KommunicateServiceBaseUrl}/rest/ws/group/conversation`,
    {
      groupName,
      groupMemberList,
    },
    {
      headers: {
        "Api-Key":
          process.env.REACT_APP_KOMMUNICATE_CHATBOT_CONVERSATION_API_KEY,
        "Content-Type": "application/json",
      },
    }
  );

/**
 * Sends an email to a list of recipients using the Customer.io API as default.
 *
 * @param emails - Array of email addresses to send the email to.
 * @param content - The content of the email to be sent of type [ShareEmailContentType].
 * @param emailTemplateId - ID of the email template to use for sending the email.
 *
 * @returns A promise that resolves when all emails have been sent or rejects if any of the requests fail.
 */

export const sendEmailViaCustomerIo = async (
  emails: string[],
  content: ShareEmailContentType,
  emailTemplateId: string
) => {
  const url = `https://api.customer.io/v1/send/email`;

  const promises = emails.map((email: string) => {
    const data = JSON.stringify({
      to: email,
      transactional_message_id: emailTemplateId,
      identifiers: { email },
      message_data: content,
      disable_message_retention: false,
      send_to_unsubscribed: false,
      tracked: true,
      queue_draft: false,
      disable_css_preprocessing: true,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_CUSTOMER_IO_API_KEY}`,
        "content-type": "application/json",
      },
      data: data,
    };

    return axios.request(config);
  });

  try {
    await Promise.all(promises);
    alertNotification("success", "Email sent successfully", "top-right");
  } catch (error) {
    alertNotification("error", "Failed to send some email", "top-right");
  }
};

// Dashboard request...

//new migration api to get dashboard tabs
export const getDashboardTabsData = async (journey_slug?: string) =>
  await makeApiCall(
    "v1",
    "GET",
    `survey/legacy/static/dashboard?journeySlug=${journey_slug}`
  );

// Notes

export const getNotes = async (journey_slug?: string) =>
  await makeLegacyApiCall("GET", `patient-notes?journey_slug=${journey_slug}`);

//new migration api to get Notes
export const getNotesData = async () =>
  await makeApiCall("v1", "GET", `survey/legacy/notes`);

export const updateNote = async ({
  id,
  data,
}: {
  id: number | undefined;
  data: string | undefined;
}) =>
  await makeLegacyApiCall("PATCH", `update-patient-note/${id}`, {
    patient_note_content: data,
  });

//new migration api to update Notes
export const updatedNotesData = async ({
  id,
  data,
}: {
  id: number | undefined;
  data: string | undefined;
}) =>
  await makeApiCall("v1", "PUT", `survey/legacy/updateNote/${id}`, {
    patient_note_content: data,
  });

// Goals

export const getGoals = async (journeyId: number) =>
  await makeApiCall("v1", "GET", `goal/findByAuth?journeyId=${journeyId}`);

export const updateGoal = async (data: any) =>
  await makeApiCall("v1", "PUT", `goal/updatePatientGoal`, data);

export const toggleGoal = async (data: {
  journeyId: Journey["id"];
  journeyGoalId: JourneyGoal["id"];
  id?: PatientGoal["id"];
  uuid?: string;
}) => await makeApiCall("v1", "POST", `goal/toggle`, data);

export const createGoal = async (data: any) =>
  await makeApiCall("v1", "POST", `patientGoal/create`, data);

export const deleteGoal = async (id: any) =>
  await makeApiCall("v1", "DELETE", `patientGoal/${id}`);

// Updated Goal
export const fetchRecommendedGoals = async (journeyId: Journey["id"]) =>
  await makeApiCall("v1", "GET", `goal/recommended/${journeyId}`);

export const createGoalActivityRequest = async (data: any) =>
  await makeLegacyApiCall("POST", `patient-goal-activities`, { ...data });

export const updateGoalActivityRequest = async (
  data: number,
  goal_id: number
) =>
  await makeLegacyApiCall("PUT", `patient-goal-activities/${goal_id}`, {
    status: data,
  });

export const updatePatientGoalProgressRequest = async (data: any) =>
  await makeApiCall("v1", "PUT", `patientGoal/progress`, { ...data });

export const destroyGoalActivity = async (activityId: number) =>
  await makeLegacyApiCall("DELETE", `patient-goal-activities/${activityId}`);
// Journal
export const getAllJournalSurvey = async (journey_slug?: string) =>
  await makeApiCall("v1", "GET", `survey/legacy/oab/journal/table`);
export const getJournalSurveyChartData = async (journey_slug?: string) =>
  await makeApiCall("v1", "GET", `survey/legacy/oab/journal/chart`);
export const getJournalOverviewChartData = async (journey_slug?: string) =>
  await makeLegacyApiCall(
    "GET",
    `journal-overview?journey_slug=${journey_slug}`
  );
export const createJournalSurvey = async (data: any) =>
  await await makeApiCall("v1", "POST", `/survey/legacy/oab/journal/add`, data);
export const updateJournalSurvey = async (
  id?: number,
  data?: { journey_slug?: string; title?: string }
) => await makeLegacyApiCall("PATCH", `journal-survey/${id}`, data);
export const getJournalSurveyForm = async (id: string) =>
  await makeLegacyApiCall("GET", `journal-survey-form?journal_rule_id=${id}`);

export const getJournalCSVContent = async () =>
  await makeApiCall(
    "v1",
    "GET",
    `survey/legacy/oab/journal/csv`,
    undefined,
    undefined,
    undefined,
    { responseType: "blob" }
  );

export const getJournalPDFContent = async () =>
  await makeApiCall(
    "v1",
    "GET",
    `survey/legacy/oab/journal/pdf`,
    undefined,
    undefined,
    undefined,
    { responseType: "blob" }
  );

export const getConfigurationsRequest = async (journey_slug?: string) =>
  await makeApiCall(
    "v1",
    "GET",
    `patientGoal/patientSubscriptions?journeySlug=${journey_slug}`
  );

export const setConfigurationsRequest = async (id: number, data: any) =>
  await makeApiCall(
    "v1",
    "PUT",
    `patientGoal/updatePatientSubscription/${id}`,
    data
  );

export const getPatientSubscriptions = async (email: string) =>
  await makeLegacyApiCall("POST", `patient-subscriptions`, { key: email });

export const updatePatientSubscriptions = async (data: any) =>
  await makeLegacyApiCall("POST", `update-subscription-preferences`, {
    ...data,
  });

// System configurations
export const getSystemConfigurationsRequest = async () =>
  await axios.get("/configuration.json");

export const storePatientInAppGoalNotifications = async () =>
  await makeLegacyApiCall("GET", `inapp-goal-notifications`);

/** New API calls */
// Below API is to get the List of symptoms based on Journey Id from new API
export const getSymptomsByJourneyId = async (journeyId: unknown) =>
  await makeApiCall("v1", "GET", `symptom/findByJourney/${journeyId}`);

export const getPatientJourneyProgressesByPatient = async (
  journeyId?: number
) =>
  await makeApiCall<unknown>(
    "v1",
    "GET",
    `journeyProgress/legacy/findByPatient`,
    undefined,
    undefined,
    { journeyId }
  );

export const getTreatmentSummeryByPatientJourneyId = async (params: {
  journeyId?: number;
}) =>
  await makeApiCall<Treatment[]>(
    "v1",
    "GET",
    `treatment/findSummary`,
    undefined,
    undefined,
    params
  );

export const getVideoDetailsById = async (mediaId: string) =>
  await makeApiCall<Video>(
    "v1",
    "GET",
    `public/media/videoDetailsByMediaId/${mediaId}`
  );

// scheduling related API calls
export const makeVirtualVisit = async (data: Schedule) =>
  await localStorage.setItem("virtualVisit", JSON.stringify(data));

export const getVirtualVisit = async () =>
  await JSON.parse(localStorage.getItem("virtualVisit") as string);

export const removeVirtualVisit = async () =>
  await localStorage.removeItem("virtualVisit");

export const makeInPersonVisit = async (data: Schedule) =>
  await localStorage.setItem("inpersonVisit", JSON.stringify(data));

export const getInPersonVisit = async () =>
  await JSON.parse(localStorage.getItem("inpersonVisit") as string);

export const removeInPersonVisit = async () =>
  await localStorage.removeItem("inpersonVisit");

// new journals related API calls

export const getPatientJournals = async () =>
  await makeApiCall<PatientJournal[]>("v1", "GET", `patientJournal/findByAuth`);

export const getMoodReport = async () =>
  await makeApiCall("v1", "get", `patientJournal/getMoodReport`);

export const createPatientJournalRequest = async (
  data: Omit<PatientJournal, "uuid">
) =>
  await makeApiCall<PatientJournal[]>(
    "v1",
    "post",
    `patientJournal/create`,
    data
  );

export const getPatientJournalEntries = async () =>
  await makeApiCall<JournalEntry[]>(
    "v1",
    "get",
    `patientJournal/journalEntry/findByAuth`
  );

export const updateJournalEntryRequest = async (data: Partial<JournalEntry>) =>
  await makeApiCall<JournalEntry[]>(
    "v1",
    "put",
    `patientJournal/journalEntry/update`,
    data
  );

export const createJournalEntry = async (data: Omit<JournalEntry, "uuid">) =>
  await makeApiCall<JournalEntry>(
    "v1",
    "post",
    `patientJournal/journalEntry/create`,
    data
  );

export const duplicateJournalEntryRequest = async (
  data: Omit<JournalEntry, "id">
) =>
  await makeApiCall<JournalEntry>(
    "v1",
    "post",
    `patientJournal/journalEntry/duplicate`,
    data
  );

export const deleteJournalEntryRequest = async (
  entryUuid: JournalEntry["uuid"]
) =>
  await makeApiCall<JournalEntry[]>(
    "v1",
    "put",
    `patientJournal/journalEntry/delete/uuid/${entryUuid}`
  );

export const searchJournalEntryRequest = async (params: {
  searchString: string | number;
  patientJournalUuid?: PatientJournal["uuid"];
  dateFilter?: string;
}) => {
  let queryString = "";
  if (params.searchString) queryString = `searchString=${params.searchString}`;
  if (params.dateFilter)
    queryString = queryString + `&dateFilter=${params.dateFilter}`;
  if (params.patientJournalUuid)
    queryString =
      queryString + `&patientJournalUuid=${params.patientJournalUuid}`;

  return await makeApiCall<JournalEntry[]>(
    "v1",
    "GET",
    `patientJournal/journalEntry/search?${queryString}`,
    undefined,
    undefined,
    undefined
  );
};

export const getFilteredJournalEntry = async (filters: {
  drinkFilter?: string;
  moodFilter?: Number;
  dateFilter?: string;
  patientJournalFilter?: PatientJournal["uuid"];
}) =>
  await makeApiCall<FilteredJournalResponse>(
    "v1",
    "GET",
    "patientJournal/entries",
    undefined,
    undefined,
    filters
  );

export const getPeeDiary = async () =>
  await makeApiCall("v1", "get", `patientJournal/getPeeDiary`);

//Kegel Tool

export const getKegelCounterHistory = async () =>
  await makeApiCall("v1", "get", `kegel/history`);

export const createKegelCounterHistory = async (data: any) =>
  await makeApiCall("v1", "POST", `kegel/create`, data);

export const getkegelsetting = async () =>
  await makeApiCall("v1", "get", `kegel/setting`);

export const updatekegelsetting = async (data: any) =>
  await makeApiCall("v1", "PUT", `kegel/update`, data);

export const sessionClose = async () => {
  try {
    await makeApiCall("v1", "POST", `patient/closeSession`);
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 302) {
        console.error("Redirecting to:", error.response.headers.location);
      }
    } else {
      console.error("Error closing session:", error.message);
    }
  }
};

// Symptom Tracker APIs
export const createSymptomEntry = async ( data: Pick<SymptomEntry,"date"|"parameters">
) =>
 await makeApiCall<SymptomEntry>(
    "v1",
    "post",
    `patientJournal/symptomEntry/create`,
    data
  );

export const updateSymptomEntry = async ( data: Pick<SymptomEntry,"date"|"parameters">
) =>
  await makeApiCall<SymptomEntry[]>(
    "v1",
    "put",
    `patientJournal/symptomEntry/update`,
    data
  );
  
export const fetchSymptomEntries = async (params: {
  dateFilter?: string;
}) =>
  await makeApiCall<SymptomEntry[]>(
    "v1",
    "get",
    `patientJournal/symptomEntries`,
    undefined,
    undefined,
    params
  );
