import { useEffect, useState } from "react";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { CoffeeSvg, DrinkSvg, WaterSvg } from "../../../../Images/icons/Icons";
import { getPeeDiary } from "../../../../core/requests/_requests";
import { JournalEntry } from "../../../../types/journal";
export function PeeDiaryChart({ journalEntries, gotoJournalTab, trendData }: 
  { journalEntries: JournalEntry[]; gotoJournalTab: () => void; trendData: (data: string) => void; }) {
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const data = await getPeeDiary();
      if (data && data.peeDiaryDTO) {
        //trendData(data.trend.toLowerCase()==='up')
        trendData(data.trend?.toLowerCase());
        const dynamicPageData = data.peeDiaryDTO.map((day: { liquidIntake: [], weekDay: string }) => {
          const liquidCounts = {
            water: 0,
            coffee: 0,
            soda: 0,
          };

          day.liquidIntake.forEach((intake: { liquidType: keyof typeof liquidCounts; liquidCount: string }) => {
            if (intake.liquidType in liquidCounts) {
              liquidCounts[intake.liquidType] += parseInt(intake.liquidCount, 10);
            }
          });

          return {
            label: day.weekDay.slice(0, 2).charAt(0).toUpperCase() + day.weekDay.slice(1, 2).toLowerCase(),
            ...liquidCounts,
            total: Object.values(liquidCounts).reduce((sum, count) => sum + count, 0),
          };
        });

        // const modifiedData = dynamicPageData.map((val:any) => {
        //   val.total = val.coffee + val.soda + val.water;
        //   return val;
        // });
        // return pageData;
        setChartData(dynamicPageData);
      }
    };

    fetchData();
  }, [journalEntries]);

  const CustomizedDot = (props: any) => {
    const { cx, cy, value } = props;
    if (cy === null) {
      return <></>;
    } else if (props.payload.total > 0) {
      return (
        <>
          {props.payload.coffee && <CoffeeSvg cx={cx} cy={cy} num={props.payload.coffee} showNum />}
          {props.payload.water && <WaterSvg cx={cx} cy={cy} num={props.payload.water} showNum />}
          {props.payload.soda && <DrinkSvg cx={cx} cy={cy} num={props.payload.soda} showNum />}
        </>
      );
    } else {
      return (
        <></>
      );
    }
  };

  const dayMap: { [key: string]: string } = {
    Mo: "Monday",
    Tu: "Tuesday",
    We: "Wednesday",
    Th: "Thursday",
    Fr: "Friday",
    Sa: "Saturday",
    Su: "Sunday",
  };

  const CustomTooltip = ({ payload, label, active, coordinate }: any) => {
    if (active && payload && payload.length) {
      const { water, coffee, soda } = payload[0].payload;
      const { x, y } = coordinate;
      return (
        <div
          className="bg-white border border-[#ccc] rounded-sm p-1"
          style={{
            position: "absolute",
            top: y + 15,
            left: x - 120,
            minWidth: "120px",
            zIndex: "1",
          }}
        >
          <div className="flex flex-col mb-1">
            <div className="flex justify-between">
              <p className="flex justify-between text-xs font-semibold">{`Day: `}</p>
              <p className="flex justify-between text-xs font-semibold">{` ${dayMap[label] || label
                }`}</p>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-1">
            <div className="col-span-1">
              <div className="flex flex-col gap-0.5">
                {water > 0 && (<WaterSvg />)}
                {coffee > 0 && (<CoffeeSvg />)}
                {soda > 0 && (<DrinkSvg />)}
              </div>
            </div>
            <div className="col-span-1 text-xs">
              <div className="flex flex-col gap-4">
                {water > 0 && (<span>Water</span>)}
                {coffee > 0 && (<span>Coffee</span>)}
                {soda > 0 && (<span>Soda</span>)}
              </div>
            </div>
            <div className="col-span-1 text-right text-xs">
              <div className="flex flex-col gap-4">
                {water > 0 && (<span className="font-normal">{water}</span>)}
                {coffee > 0 && (<span className="font-normal">{coffee}</span>)}
                {soda > 0 && (<span className="font-normal">{soda}</span>)}
              </div>
            </div>
          </div>

          <hr className="my-1 border-gray-300" />

          <div className="flex justify-between text-xs">
            <span className="font-semibold">Total:</span>
            <span className="font-semibold">{payload[0].value}</span>
          </div>
        </div>
      );
    }
    return null;
  };

  const handleNavigation = () => {
    if (journalEntries?.length === 0) {
      gotoJournalTab()
    }
    return;
  }

  return (
    <>
      <div className="w-full h-52">
        <ResponsiveContainer>
          <AreaChart
            className={`${journalEntries?.length === 0 ? '!cursor-pointer' : '!cursor-default'}`}
            onClick={handleNavigation}
            width={560}
            height={250}
            data={chartData}
            syncId="peeDairy"
            margin={{
              top: 25,
              right: 25,
              left: 25,
              bottom: -8,
            }}
          >
            <XAxis dataKey="label" stroke="black" tickLine={false} axisLine={false} fontWeight="Inter" fontSize={12} />
            {journalEntries?.length > 0 && (
              <Tooltip content={<CustomTooltip />} />
            )}
            <Area
              type="monotone"
              dataKey="total"
              fillOpacity="0.05"
              fill="#000000"
              stroke={`${journalEntries?.length === 0 ? 'none' : '#3182bd'}`} 
              dot={<CustomizedDot />}
            />
            {journalEntries?.length === 0 && (
              <>
                <text
                  x="50%"
                  y="50%"
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fill="black"
                  fontSize={16}
                  cursor="pointer"
                  onClick={() => gotoJournalTab()}
                >
                  Click Here to Journal
                </text>
              </>
            )}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}

export default PeeDiaryChart

