import { useAuth0 } from "@auth0/auth0-react";
import { useAppSelector } from "../../redux/store";
import { sessionClose } from "../requests/_requests";

const useAuthOLogout = () => {
  const keysToPreserve: string[] = ["virtualVisit", "inpersonVisit"];

  const currentJourneySlug: string = useAppSelector(
    (state) => state.journey.currentJourneySlug
  );

  const { logout } = useAuth0();

  const auth0Logout = async () => {
    await sessionClose();
    const preservedData: Record<string, string> = {};

    keysToPreserve.forEach((key) => {
      const value = localStorage.getItem(key);
      if (value) {
        preservedData[key] = value;
      }
    });

    localStorage.clear();

    Object.keys(preservedData).forEach((key) => {
      localStorage.setItem(key, preservedData[key]);
    });

    logout({
      logoutParams: {
        returnTo: `${window.location.origin}?slug=${currentJourneySlug}`,
        federated: true,
      },
    });
  };

  return { auth0Logout };
};

export default useAuthOLogout;
