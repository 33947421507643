import classNames from "classnames";
import { getPatientSubscriptions, updatePatientSubscriptions } from "../../core/requests/_requests";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Checkbox, CheckboxGroup } from "rizzui";
import { alertNotification } from "../../core/alert/ToastAlert";
import { RootState, useAppSelector } from "../../redux/store";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

const Unsubscribe = () => {

  const imageUrl: string = useAppSelector((state: RootState) => state.layout.imageSource);

  const [searchParams] = useSearchParams();
  const [values, setValues] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subscriptionList, setSubscriptionList] = useState<{ id: number, title: string }[] | []>([]);
  const [name, setName] = useState<string>();
  const [alreadyUnsubscribed, setAlreadyUnsubscribed] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const email = searchParams.get('email') ?? "";
  const navigate = useNavigate();

  const updatePreferences = async () => {
    setIsLoading(true);
    const response = await updatePatientSubscriptions({ goal_id: values.map(value => Number(value)), key: email });
    if (response.statusCode !== 200) {
      alertNotification("error", response.message, "top-right");
      setIsLoading(false);
      return;
    }
    setSuccess(true);
    setIsLoading(false);
  }

  useEffect(() => {

    const fetchData = async () => {
      const response = await getPatientSubscriptions(email);
      if (response.statusCode !== 200) {
        alertNotification("error", response.message, "top-right");
        return;
      }
      if (response.data.goals.length === 0) {
        setAlreadyUnsubscribed(true);
        return;
      }
      setSubscriptionList(response.data.goals);
      setName(response.data.patient);
      return response.data;
    };

    fetchData();

  }, [searchParams]);

  if (success) {
    return (
      <div className={classNames("fixed top-0 w-full h-full z-20")}>
        <div className="relative isolate overflow-hidden h-full" style={{ backgroundImage: `url(${imageUrl})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>

          <div className="absolute inset-0 bg-black/75 opacity-80 blur" />

          <div className="absolute left-0 right-0 top-0 bottom-0 flex flex-col items-center justify-center">
            <div className="mx-auto max-w-2xl text-center mb-4">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl mb-6">
                Subscription Updated
              </h2>
            </div>

            <div className="flex gap-2">
              <Button className="bg-green-700 hover:bg-green-700" onClick={() => navigate("/")}>
                <span>Go Back To App</span>{" "}
                <ArrowRightIcon strokeWidth="2" className="h-4 w-4 ml-2" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (alreadyUnsubscribed) {
    return (
      <div className={classNames("fixed top-0 w-full h-full z-20")}>
        <div className="relative isolate overflow-hidden h-full" style={{ backgroundImage: `url(${imageUrl})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>

          <div className="absolute inset-0 bg-black/75 opacity-80 blur" />

          <div className="absolute left-0 right-0 top-0 bottom-0 flex flex-col items-center justify-center">
            <div className='bg-white/80 p-8 rounded-xl'>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"></path>
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">No Email Subscriptions Found!</h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-800">Currently, you have not enabled email notification for any of your goals.</p>
                </div>
              </div>
              <div className="flex justify-center gap-2 mt-8">
                <Button className="bg-green-700 hover:bg-green-700" onClick={() => navigate("/")}>
                  <span>Go Back To App</span>{" "}
                  <ArrowRightIcon strokeWidth="2" className="h-4 w-4 ml-2" />
                </Button>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={classNames("fixed top-0 w-full h-full z-20")}>
      <div className="relative isolate overflow-hidden h-full" style={{ backgroundImage: `url(${imageUrl})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>

        <div className="absolute inset-0 bg-black opacity-60 blur" />

        <div className="absolute left-0 right-0 top-0 bottom-0 flex flex-col items-center justify-center">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl mb-6">
              Manage Your Email Subscription Preferences
            </h2 >
            <p className="text-white font-medium tracking-tight">{name && `Hello ${name}`}, We're sorry to see you go! If you no longer wish to receive email notifications from us for the goals you have created, please select the specific or all the goals and click the "Unsubscribe" button. You will be removed from our mailing list.</p>
          </div >
          <div className="flex flex-col justify-center text-center items-center mt-8">
            <div className="">
              <CheckboxGroup
                values={values}
                setValues={setValues}
                className="gap-4"
              >
                {
                  subscriptionList.map((subscription) => <Checkbox inputClassName='subscription_check_input' className="text-white my-2" value={(subscription?.id ?? 0).toString()} label={subscription.title} />)
                }
              </CheckboxGroup>
            </div>
          </div>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Button
              isLoading={isLoading}
              onClick={updatePreferences}
              type="button"
              className="bg-green-700 hover:bg-green-700"
            >
              Unsubscribe
            </Button>
          </div>
        </div >
      </div >
    </div >
  );
}

export default Unsubscribe;
