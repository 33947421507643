import { useState } from "react";

import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { TrandDownIcon, TrandUPIcon } from "../../../../Images/icons/Icons";
import { RootState, useAppSelector } from "../../../../redux/store";
import { PeeDiaryChart } from "./peeDiaryChart";
import { WeeklyMoodChart } from "./weeklyMoodChart";

const DiaryCharts = ({ gotoJournalTab, className }: { gotoJournalTab: () => void; className?: string }) => {
  const { journalEntries } = useAppSelector((state: RootState) => state.journals);
  const [weeklyMoodTrend, setWeeklyMoodTrend] = useState<string>('');
  const [peeDiaryTrend, setPeeDiaryTrend] = useState<string>('')
  const [overviewData, setOverviewData] = useState([
    {
      quantity: 3,
      journal_date: "06/23",
      leak_urine_volume: 1,
      urinate_time: 3,
    },
    {
      quantity: 5,
      journal_date: "06/24",
      leak_urine_volume: 2,
      urinate_time: 4,
    },
    {
      quantity: 4,
      journal_date: "06/25",
      leak_urine_volume: 2,
      urinate_time: 3,
    },
    {
      quantity: 6,
      journal_date: "06/26",
      leak_urine_volume: 2,
      urinate_time: 3,
    },
    {
      quantity: 3,
      journal_date: "06/27",
      leak_urine_volume: 1,
      urinate_time: 3,
    },
    {
      quantity: 5,
      journal_date: "06/28",
      leak_urine_volume: 2,
      urinate_time: 4,
    },
    {
      quantity: 4,
      journal_date: "06/29",
      leak_urine_volume: 2,
      urinate_time: 3,
    },
    {
      quantity: 6,
      journal_date: "06/30",
      leak_urine_volume: 2,
      urinate_time: 3,
    },
  ]);

  const getTrendIcon = (trend: string) => {
    switch (trend) {
      case 'up':
        return <TrandUPIcon />;
      case 'neutral':
        return <ArrowRightIcon strokeWidth="2" className="h-5 w-6 mt-[3px] text-yellow-500" />;
      case 'down':
        return <TrandDownIcon />;
      default:
        return null;
    }
  };

  return (
    <div className={`flex flex-row ${className} gap-3`}>
      <div className="w-1/2 rounded-lg p-3 bg-[#D9D9D9]">
        <div className="flex">
          <h5 className="text-sm font-bold mb-2 mr-1">Weekly Mood</h5>
          {journalEntries?.length > 0 && (
            <span className="mt-[-3px]">
              {getTrendIcon(weeklyMoodTrend)}
            </span>
          )}
        </div>
          <div className={`${journalEntries?.length === 0 ? 'bg-[#D9D9D9] rounded-lg' : 'bg-[#EAE9E3] rounded-lg'}`}>
          <WeeklyMoodChart journalEntries={journalEntries} gotoJournalTab={gotoJournalTab} weeklyMoodTrendData={(data: string) => { setWeeklyMoodTrend(data) }} />
        </div>
      </div>
      <div className="w-1/2 rounded-lg p-3 bg-[#D9D9D9]">
        <div className="flex">
          <h5 className="text-sm font-bold mb-2 mr-1">Pee Dairy</h5>
          {journalEntries?.length > 0 && (
            <span className="mt-[-3px]">
              {getTrendIcon(peeDiaryTrend)}
            </span>
          )}
        </div>
        <div className={`${journalEntries?.length === 0 ? 'bg-[#D9D9D9] rounded-lg' : 'bg-[#EAE9E3] rounded-lg'}`}>
          <PeeDiaryChart journalEntries={journalEntries} gotoJournalTab={gotoJournalTab} trendData={(data: string) => { setPeeDiaryTrend(data) }} />
        </div>
      </div>
    </div>
  );
};
export default DiaryCharts;
