import { useNavigate } from "react-router-dom";
import { setShowNudge } from "../../../../redux/journals/JournalSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";

const JournalNudge = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentJourneySlug: string =
    useAppSelector((state) => state.journey.currentJourneySlug)

  const gotoJournalTab = () => {
    dispatch(setShowNudge(false));
    navigate(`/${currentJourneySlug}/dashboard?activeTab=journal`);
  }

  const handleCloseModal = () => {
    dispatch(setShowNudge(false));
  };

  return (
    <div className="fixed overflow-y-auto h-full top-0 w-full z-[10]">
      <div className="min-h-[100vh] flex justify-center bg-black/50">
        <div
          className="inline-block mt-14 relative max-w-[48%] w-full min-h-[73vh] max-h-[73vh] rounded-md shadow-lg container_border"
        >
          <div className="absolute bg-white top-3 bottom-3 left-3 right-3 rounded-md overflow-y-auto scrollbar">
            <div className="h-full relative inline-block w-full">
              <div className="flex flex-col h-full relative bg-[#f5f5f5]">
                <div className="flex-none">
                  <div className="grid grid-cols-12 gap-4 py-2">
                    <div className="col-start-2 col-end-12">
                      <div className="flex goals-center flex-col px-2.5">
                        <h2 className="text-center text-2xl font-semibold my-3">
                          Stay on Track with Your Health Journey!
                        </h2>
                        <p className="px-4 text-center font-medium text-base my-2">
                          <b>Don't forget to log your daily progress!</b> Keeping a journal helps you stay aware and <br /> motivated in managing your health. Take a moment today to record.
                        </p>
                        <p className="flex justify-center text-sm py-1">
                          Other users who have reported Frequency are using:
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-1 overflow-y-auto scrollbar mx-2">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-start-2 col-end-12">
                      <div className="container w-full px-4 rounded-md min-h-[400px] bg-[#DCEDF3]">
                        <br />
                        <p className="flex justify-center my-6">
                          💧<b>&nbsp;Daily Liquid Intake:&nbsp;</b> How much water or fluids did you have today?
                        </p>
                        <p className="flex justify-center my-6">
                          💪<b>&nbsp;Kegel Exercises:&nbsp;</b> Track your progress and efforts to strengthen your pelvic floor.
                        </p>
                        <p className="flex justify-center my-6">
                          😊<b>&nbsp;Moods & Feelings:&nbsp;</b> How are you feeling today? Any changes or improvements?
                        </p>
                        <p className="flex justify-center my-6">
                          📝<b>&nbsp;Additional Notes:&nbsp;</b> Anything else you'd like to add about your day or health journey?
                        </p>
                        <p className="flex items-center gap-2 justify-center font-semibold text-center my-2">
                          Journaling is a great way to reflect, stay on top of your goals, and track your <br /> progress over time!
                        </p>
                        <p className="flex items-center gap-2 justify-center text-center mt-6">
                          Would you like to make an entry now?
                        </p>
                      </div>
                      <div className="flex justify-center mt-3 gap-9">
                        <button type="button" className="bg-[#00b395] p-2 text-white rounded-xl" onClick={gotoJournalTab}>
                          Yes, Take Me to My Journal
                        </button>
                        <button type="button" className="bg-[#0077bb] p-2 text-white rounded-xl" onClick={handleCloseModal}>
                          Remind Me Later
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default JournalNudge;